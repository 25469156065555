<template>
      <div class="container">
		<div class="row">
			<div class="col-12">
				<div class="sign__content">
               <form class="sign__form" @submit.prevent="Subscrib()">
                  <router-link to="/" class="sign__logo">
                     <img src="static/img/logoSvg.svg" alt="">
                  </router-link>
						<div class="sign__group">
                     <p class="" style="font-size: 21px; text-align: center; margin-bottom: 10px; font-family:'Cairo' ,sans-serif;width: 100%;">alsukare</p>
                     <p style="text-align:justify;font-family:'Cairo' ,sans-serif;font-weight:600;">
                        هي خدمة طبية تعنى بالتعرف على مرضى السكري , أنواعه , التغذية السليمة وطرق الوقاية منه والتعايش معه لدى الكبار والصغار بمعلومات علمية موثقة تم إعدادها بواسطة البروفيسور محمد علي التوم إستشاري السكري والغدد الصماء
                     </p>
                     <!-- <input type="phone" v-model="msisdn" class="sign__input input" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = '09xxxxxxxx'"> -->
						</div>
                  <div class="sign__group">
                     <!-- <button class="sign__btn" type="submit">تسجيل دخول</button> -->
                     <button class="sign__btn button" style="color:#fff" >اشــتراك
                        <!-- <span v-if="loading" class="px-1 ">جاري اشــتراك</span> &nbsp;
                        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        <span v-else class="arabickufi">اشــتراك</span> -->
                     </button>
                     <span class="sign__delimiter"><h6></h6></span>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter, useRoute } from "vue-router"
export default {
  name: 'AppSubscribes',
    mounted() {
        if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
            return 
        } else {
            return this.$router.go(-1)
        }
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const Subscrib = () => {
            // window.open("http://test.zaindsp.com:3033/?p=6122155252");
            window.open("https://dsplp.sd.zain.com/?p=2416749956", "_self");
        }
        return { Subscrib }
    }
}
</script>

<style>


</style>